<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="1200">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ this.title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    v-if="loading"
                >

                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>

                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>

                </v-card-text>
                <v-card-text
                    v-else
                    class="wrapperFormModal"
                >
                    <v-card 
                        flat
                    >
                        <v-card-text>
                            <div class="form-box-title title-with-right-part">
                                <span>
                                    {{ $t("Доступно") }}
                                </span>
                                <div class="twrp-content">
                                    <v-text-field
                                        v-model="search"
                                        :placeholder="$t('Поиск')"
                                        hide-details
                                        dense
                                        clearable
                                        required
                                        @click:clear="search=''"
                                        class="twrp-search"
                                        append-icon="fas fa-search"
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                            <v-virtual-scroll
                                v-if="enterprises.length > 0"
                                :items="enterprises"
                                :item-height="40"
                                :height="windowHeight >= 937 ? '400' : '200'"
                                :bench="10"
                                class="virt-scrl-list-tree"
                            >
                                <template v-slot:default="{ item, i }">
                                    <v-list-item
                                        :key="i"
                                        :value="item"
                                    >
                                        <v-list-item-action class="group-item-action">
                                            <v-checkbox
                                                color="primary"
                                                hide-details
                                                class="p-0 allow-parent-selection-checkbox"
                                                :input-value="selectedInclude(item.id)"
                                                @click.native.capture="onItemClick(item, $event)"
                                            >
                                            </v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-icon>

                                            <v-icon small>
                                                fas fa-landmark
                                            </v-icon>

                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span
                                                    :class="['font-weight-medium', 'text-uppercase']"
                                                    v-html="highlight(item.name)"
                                                >
                                                </span>
                                            </v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>

                                </template>
                            </v-virtual-scroll>    
                            <div
                                v-else
                                class="div-table-row"
                            >
                                <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
                            </div>

                        </v-card-text>
                    </v-card>

                    <!--Блок "Выбрано"-->
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title title-with-right-part">
                                <span>
                                    {{ $t("Выбрано") }}
                                </span>
                                <div class="twrp-content">
                                    <div 
                                        v-tooltip.top-center='$t("Удалить_все")'                                        
                                        v-if="selected.length"
                                        @click.stop="onRemoveAllSelected"
                                    >
                                        <v-icon role="button" small left>
                                            fas fa-trash
                                        </v-icon>
                                    </div>

                                </div>
                            </div>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="12">

                                    <div
                                        v-if="selected.length"
                                        class="onlyReadData more-per-lab-wrap three-per-lab-wrap"
                                    >
                                        <template v-for="(item, i) in selected">
                                            <v-enterprise-chip
                                                :id="item.id"
                                                :name="item.name"
                                                :key="i"
                                                :canDelete="true"
                                                :delFunc="onRemoveSelectedByEnterpriseId"
                                            />
                                        </template>
                                    </div>
                                    <div
                                        v-else
                                        class="cfl-placeholder"
                                    >
                                        {{ $t("Нет_выбранных_элементов") }}
                                    </div>

                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
              
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        @click="ok" 
                        v-if="valid"
                    >
                        {{ $t("Выбрать") }}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{ $t("Отмена") }}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "SelectEnterpriseDlg",
    data () {
        return {
            title: i18n.t("Организация"),
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            selectedItems: []
        }
    },
    computed: {
        ...mapGetters('dialogs/selectEnterprise',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            valid: 'isValid',
            enterprises: 'getEnterprises'
        }),
        search: {
            get: function() {
                return this.$store.getters['dialogs/selectEnterprise/getSearch'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectEnterprise/SET_SEARCH', v); 
            }
        },
        selected: {
            get: function() {
                return this.$store.getters['dialogs/selectEnterprise/getSelected'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectEnterprise/SET_SELECTED', v); 
            }
        },
        multyple() {
            return this.$store.getters['dialogs/selectEnterprise/isMulty'];
        },
        windowHeight() {
            return window.innerHeight;
        }
    },
    methods: {
        ...mapActions('dialogs/selectEnterprise', ['ok', 'cancel']),
        /*doubleSelect(item) {
            this.$store.commit('dialogs/selectEnterprise/SET_ENTERPRISE', item);
            this.ok();
        },*/
        onItemClick(item, event) {
            if (!item) return;
            let index = this.selected.map(x=>x.id).indexOf(item.id);
            if (index >= 0){
                    this.selected.splice(index, 1);
            }
            else {
                if (this.multyple)
                    this.selected.push({id:item.id, name:item.name});
                else 
                    this.selected = [{id:item.id, name:item.name}];
            }
            if (event)
                event.stopPropagation();
        },
        onRemoveSelectedByEnterpriseId(id){
            let index = this.selected.map(x=>x.id).indexOf(id);
            if (index >= 0)
                this.selected.splice(index, 1);
        },
        onRemoveAllSelected(){
            this.selected = [];
        },
        selectedInclude(id){
            return  this.selected.map(x=>x.id).includes(id);
        },
        highlight(content) {
            if (!this.search)
                return content;

            let filter = this.search.trim();

            if (!filter)
                return content;

            let searchTokens = filter.split(' ');
            let fullsearchsuccess = false;
            let rez = String(content).replace(new RegExp(filter, "gi"), match => {
                fullsearchsuccess = true;
                return `<span style="background:#C5E1A5">${match}</span>`;
            });
            
            if (!fullsearchsuccess && searchTokens.length > 1) {
                searchTokens.forEach(element => {
                    if (element) {
                        rez = String(rez).replace(new RegExp(element, "gi"), match => {
                            return `<span style="background:#C5E1A5">${match}</span>`;
                        });
                    }
                });
            }

            return rez;
        },
    }
}
</script>